import React from 'react'
import projectservice from '../../assets/images/projects/confio/display.jpg'


const Details = () => {
    return (
        <section className="services-details-area ptb-100" style={{backgroundColor:'white'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                       
                        <div className="services-details-desc">
                            <h1> Features </h1>
                            
                                <div className="content">          
                                     <h3>Sample Collection Management</h3>
                                        <ul>
                                            <li>View pending list of generated requests and ordered test list from All Lab departments..</li>
                                            <li>Assign phlebotomist for collection.</li>
                                            <li>View Phlebotomist task list.</li>
                                        </ul>
                                </div>

                           
                                <div className="content">  
                                    <h3>Smart Phone Application</h3>
                                        <ul>
                                            <li>Collection of samples according to assigned requests.</li>
                                            <li>Label Printing through Portable Printers retrieving all necessary information on a single label.</li>
                                            <li>Download Phlebotomist task list on smart to collect sample without internet connectivity.</li>
                                        </ul>
                                </div>  

                                <div className="content">  
                                    <h3>Sample Receiving at Phlebotomist Department</h3>
                                        <ul>
                                            <li>Sample traceability through barcode scanning ensuring that all required tubes for the requested order has been collected.</li>
                                            <li>Request for redraw in case of wrong sample collected or inappropriate sample condition.</li>
                                        </ul>
                                </div>  

                                <div className="content">  
                                    <h3>Productivity Report</h3>
                                        <ul>
                                            <li>Comparative productivity analysis of each employee on the basis of collection and redraws.</li>
                                        </ul>
                                </div>                                  
                        </div>
                    </div>    
                </div>
            </div>
        </section>
    )
}

export default Details