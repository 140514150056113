import React from 'react'
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import {Link} from 'gatsby'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ]
};


// const CaseStudiesDetailsContent = () => {
//     const [display, setDisplay] = React.useState(false);

//     React.useEffect(() => {
//         setDisplay(true);
//     }, [])
const Banner = () => {
    const [collapsed, setCollapsed] = useRecoilState(collapsedState);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }
    // Popup Video
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

    // // Owl Carousel
    // const [display, setDisplay] = React.useState(false);

    // React.useEffect(() => {
    //     setDisplay(true);
    // }, [])

     // Owl Carousel
     const [display, setDisplay] = React.useState(false);

     React.useEffect(() => {
         setDisplay(true);
     }, [])
 

return (
    <React.Fragment>
        {/* If you want to change the video need to update videoID */}
        {/* <ModalVideo 
            channel='youtube' 
            isOpen={!isOpen} 
            videoId='bk7McNUjWgw' 
            onClose={() => setIsOpen(!isOpen)} 
        /> */}



{display ? <OwlCarousel 
    className="home-slides owl-carousel owl-theme"
    {...options}
> 

    <div className="main-banner-item item-bgC1">
   
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        <span className="sub-title">CONFIO</span>
                        <h1>Bedside sample collection</h1>
                        <p>Print your tube labels on site through portable printers to eliminate patient sample mix-up.</p>
                        
                        <div className="btn-box">
                            <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                                <span></span>
                            </Link>
                        </div>  
                    </div>

                </div>
            </div>
        </div>
    </div>


    
    <div className="main-banner-item item-bgC2">
   
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        
                        <h1>Blood collection getting smarter</h1>
                        <p>Scanning based electronic identification ensuring the right patient.</p>
                        
                        <div className="btn-box">
                            <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                                <span></span>
                            </Link>
                        </div>  
                    </div>

                </div>
            </div>
        </div>
    </div>


    <div className="main-banner-item item-bgC5">
   
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        
                        <h2>The right sample, the right patient,</h2>
                        <h2> the right labeling using smart devices </h2>
                        <h2> at bed site.</h2>
                        <p>Blood Collection Digitalization providing ease in Mobile Phlebotomy.</p>
                        
                        <div className="btn-box">
                                        {/* <Link 
                                            to="/history" 
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn"
                                        >
                                            <i className="flaticon-right"></i>Successful Deployments<span></span>
                                        </Link> */}
                            <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                                <span></span>
                            </Link>
                        </div>  
                    </div>

                </div>
            </div>
        </div>
    </div>


    <div className="main-banner-item item-bgC4">
   
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        
                        <h1>Positive patient identification</h1>
                        {/* <p>Efficient Patient care starts with Accurate Information.</p> */}
                        
                        <div className="btn-box">
                            <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                                <span></span>
                            </Link>
                        </div>  
                    </div>

                </div>
            </div>
        </div>
    </div>

{/* 
    <div className="main-banner-item item-bgC5">
   
        <div className="d-table">
            <div className="d-table-cell">
                <div className="container">
                    <div className="banner-item-content">
                   
                        <span className="sub-title">CONFIO</span>
                        <h1>Right patient right blood</h1>
                        <p>Efficient Patient care starts with Accurate Information.</p>
                        
                        <div className="btn-box">
                                        <Link 
                                            to="/history" 
                                            activeClassName="active"
                                            onClick={() => setCollapsed(true)}
                                            className="default-btn"
                                        >
                                            <i className="flaticon-right"></i>Successful Deployments<span></span>
                                        </Link>
                            <Link to="/demo" className="default-btn">
                                <i className="flaticon-right"></i> 
                                    Request a Demo
                                <span></span>
                            </Link>
                        </div>  
                    </div>

                </div>
            </div>
        </div>
    </div>

 */}

  


</OwlCarousel> : ''}
</React.Fragment>
)
}

export default Banner