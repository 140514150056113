import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Banner from '../components/Confio/Banner'
import Details from '../components/Confio/Details'
import TagsForAll from '../components/Common/TagsForAll'





const Confio = () => {
    return (
        <Layout>
        <TagsForAll pageTitle="Confio" />
            <Navbar />
            <Banner />
            <Details />
            <Footer />
        </Layout>
    );
}

export default Confio